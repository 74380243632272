import React, { useState } from 'react';
import PropTypes from 'prop-types';

const Dropdown = (props) => {
    const [drop, setDrop] = useState(false);

    /**
     * Open dropdown menu
     * @param e
     */
    const openDropdown = (e) => {
        e.preventDefault();

        if (drop !== true) {
            setDrop(true);
            document.addEventListener('click', closeDropdown);
        }
    };

    /**
     * Close dropdown menu
     */
    const closeDropdown = (e) => {
        if (e.target.classList.contains('logout') !== true) {
            setDrop(false);
            document.removeEventListener('click', closeDropdown);
        } else {
            setTimeout(() => {
                document.removeEventListener('click', closeDropdown);
            }, 250);
        }
    };

    return (
        <div className="dropdown">
            <button className="nav-link dropdown-toggle" onClick={openDropdown}>
                {typeof props.button === 'function' ? props.button() : props.button}
            </button>
            <div
                className={
                    'dropdown-menu ' +
                    (props.isList ? 'dropdown-list' : '') +
                    ' dropdown-menu-right shadow animated--grow-in' +
                    (drop === true ? ' show' : '')
                }>
                {props.children}
            </div>
        </div>
    );
};

Dropdown.propTypes = {
    button: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.func]),
    isList: PropTypes.bool,
};

export default Dropdown;
