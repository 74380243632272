import {EDIT, SET_LIST, UPDATE_LIST} from '../Actions/User';

export const UsersReducer = (state, action) => {
    switch (action.type) {
        /**
         * Mise à jour d'un utilsiateur
         */
        case EDIT: {
            return { ...state, edit: action.user };
        }
        /**
         * Liste des utilisateurs
         */
        case SET_LIST: {
            return { ...state, list: action.users };
        }
        /**
         * Mise à jour d'un utilisateur dans la liste
         */
        case UPDATE_LIST: {
            const users = state.list;
            const updatedUser = action.user;

            return {
                ...state,
                list: users.map((user) => {
                    if (user.id === updatedUser.id) {
                        return { ...user, ...updatedUser };
                    }

                    return user;
                }),
            };
        }
        default:
            return state;
    }
};

export const UsersState = {
    edit: {},
    list: [],
};
