import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Context } from '../../Store/Context';
import Locales from '../../Utils/LocalesUtils';

const Button = (props) => {
    const { languages } = useContext(Context);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(props.loading || false);
    }, [props.loading]);

    /**
     * @returns {Object}
     */
    const getOptions = () => {
        const options = {};
        options['className'] = ['btn', ...props.css];

        if (loading === true) {
            options['className'].push('btn-disabled');
            options['disabled'] = 'disabled';
            options['aria-disabled'] = true;
        }
        if (props.onClick !== undefined) {
            options['onClick'] = (e) => {
                e.preventDefault();

                setLoading(true);
                if (props.onClick !== undefined) {
                    props.onClick(e, setLoading);
                }
            };
        }
        options['className'] = options['className'].join(' ');

        return options;
    };

    /**
     * @returns {html}
     */
    const getContent = () => {
        if (loading === true) {
            return (
                <div className="spinner-border spinner-border-sm" role="status">
                    <span className="sr-only">{Locales._(languages, 'loading')}</span>
                </div>
            );
        } else {
            return props.msgContent !== undefined ? props.msgContent : Locales._(languages, props.msgId);
        }
    };

    return <button {...getOptions()}>{getContent()}</button>;
};

Button.propType = {
    css: PropTypes.array,
    loading: PropTypes.bool,
    msgContent: PropTypes.string,
    msgId: PropTypes.string,
};

export default Button;
