import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import moment from 'moment';
import useStore from '../../useStore';
import UserModel from '../../Services/Models/User';
import { usersSetList, usersUpdate } from '../../Actions/User';
import Card from '../../Views/UI/Card';
import Pagination from '../../Views/Helpers/Pagination';
import Bar from '../../Views/UI/Bar';
import DiffUtils from '../../Utils/DiffUtils';
import Locales from '../../Utils/LocalesUtils';
import Noty from '../../Utils/NotyUtils';
import ViewUtils from '../../Utils/ViewUtils';

const Manage = (props) => {
    const { dispatch, languages, routes, state } = useStore();
    const [isLoading, setLoading] = useState(true);

    useEffect(() => {
        UserModel.getUsers({
            onError: () => {
                setLoading(false);
            },
            onSuccess: (data) => {
                dispatch(usersSetList(data.response));
                setLoading(false);
            },
        });
        // eslint-disable-next-line
    }, []);

    /**
     * Modification de l'utilisateur
     * @param user
     */
    const onEditElement = (user) => {
        const route = Locales.getRoute(routes, 'users.edit', { userId: user.id });
        props.history.push(route);
    };

    /**
     * Suppréssion de l'utilisateur
     * @param user
     */
    const onDeleteElement = (user) => {
        console.log(user);
    };

    /**
     * Lors de la modification du statut
     * @param {object} data
     * @param {integer} status
     */
    const onChangeStatus = ({ data, status }) => {
        const newData = DiffUtils.deepMapper().filter({ ...data, active: status }, data);

        if (!isEmpty(newData)) {
            const user = {
                ...newData,
                id: data.id,
            };

            UserModel.setUser({
                onError: (error) => {
                    Noty.error(error.response.message);
                },
                onSuccess: (data) => {
                    dispatch(usersUpdate(user));
                    Noty.success(data.response.message);
                },
                user,
            });
        }
    };

    /**
     * Affichage du header
     * @returns {*}
     */
    const getHeader = () => {
        return (
            <div key="header" className="row row-header">
                <div className="col-1">{Locales._(languages, 'users.table.header.id')}</div>
                <div className="col-4">{Locales._(languages, 'users.table.header.mail')}</div>
                <div className="col-3">{Locales._(languages, 'users.table.header.name')}</div>
                <div className="col-1">{Locales._(languages, 'users.table.header.active')}</div>
                <div className="col-3">{Locales._(languages, 'users.table.header.lastModified')}</div>
            </div>
        );
    };

    /**
     * Affichage d'une ligne
     * @param element
     * @returns {*}
     */
    const getTemplate = (element) => {
        return (
            <div key={element.id} className="row mt-1">
                <div className="col-1">
                    <Bar history={props.history} data={element} onEdit={onEditElement} onDelete={onDeleteElement} />
                </div>
                <div className="col-4">{element.mail}</div>
                <div className="col-3">{element.name}</div>
                <div className="col-1">{ViewUtils.getStatus({ data: element, languages, status: element.active, onChange: onChangeStatus })}</div>
                <div className="col-3">{moment(element.lastModified).format('DD-MM-YYYY HH:mm:ss')}</div>
            </div>
        );
    };

    return (
        <>
            <Card title={'users.table.title'}>
                <Pagination
                    isLoading={isLoading}
                    header={getHeader}
                    template={getTemplate}
                    data={(state.users && state.users.list) || []}
                    limit={12}
                    page={2}
                />
            </Card>
        </>
    );
};

Manage.propTypes = {
    history: PropTypes.object,
};

export default Manage;
