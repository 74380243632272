import React from 'react';
import useStore from '@src/useStore';
import Modal from '@src/Views/UI/Modal';
import Locales from "@src/Utils/LocalesUtils";

const ResultGame = () => {
    const name = 'boargames.resultGame';
    const { languages, state } = useStore();

    return (
        <Modal key="boardgame.modalResultGame" name={name} title={Locales._(languages, 'games.boardgame.modalFindGame.result')}>
            <p>
                {(state.boardgames.findGame && state.boardgames.findGame.title) || Locales._(languages, 'noResult')}
            </p>
        </Modal>
    );
};

export default ResultGame;
