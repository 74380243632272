import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Context } from '../../Store/Context';
import NotyUtils from '../../Utils/NotyUtils';
import Locales from '../../Utils/LocalesUtils';
import User from '../../Services/Models/User';
import Loader from '../Helpers/Loader';
import Sidebar from '../Elements/Sidebar';
import NavBar from '../Elements/Navbar';

const Frontend = (props) => {
    const [isMounted, setMounted] = useState(false);
    const { languages } = useContext(Context);

    useEffect(() => {
        setTimeout(() => {
            checkUser(props.isPrivate);
        }, 450);
        // eslint-disable-next-line
    }, []);

    /**
     * Vérifie la connexion de l'utilisateur
     * S'il est connecté, et sur la page de connexion, on le redirige
     * S'il n'est pas connecté, on le redirige vers la page de connexion
     *
     * @param isPrivate bool
     * @returns {*}
     */
    const checkUser = (isPrivate) => {
        if (isPrivate === false) {
            setMounted(true);
        } else {
            User.check((e) => {
                NotyUtils.error(Locales._(languages, 'unauthorized'));

                return e.response;
            }).then((data) => {
                if (isPrivate === true && data && data.isValidToken === undefined) {
                    setMounted(true);
                    props.history.push('/');
                } else if (data && data.isValidToken === true) {
                    setMounted(true);
                } else {
                    setMounted(true);
                    props.history.push('/');
                }
            });
        }
    };

    return isMounted ? (
        <div id="wrapper">
            <Sidebar />
            <div id="content-wrapper" className="d-flex flex-column">
                <div id="content">
                    <NavBar />
                    <div className="container-fluid">{props.children}</div>
                </div>
            </div>
        </div>
    ) : (
        <Loader large />
    );
};

Frontend.propTypes = {
    children: PropTypes.object,
    history: PropTypes.object,
    isPrivate: PropTypes.bool,
};

export default Frontend;
