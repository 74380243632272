import React, { useContext, useEffect } from 'react';
import { Context } from '../../../Store/Context';
import Locales from '../../../Utils/LocalesUtils';
import Modal from '../../../Views/UI/Modal';

const List = (props) => {
    const name = props.name || 'default';
    const { languages } = useContext(Context);

    useEffect(() => {}, []);

    return (
        <Modal name={name} title={Locales._(languages, 'meals.elements.title')}>
            <p>List</p>
        </Modal>
    );
};

List.propTypes = {};

export default List;
