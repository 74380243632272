import {
    ADD_ELEMENT,
    ADD_RECIPE,
    EDIT_CATEGORY,
    EDIT_ELEMENT,
    EDIT_RECIPE,
    REMOVE_ELEMENT,
    SET_CATEGORIES,
    SET_RECIPES,
} from '../Actions/Meals';

export const MealsReducer = (state, action) => {
    switch (action.type) {
        case ADD_ELEMENT: {
            return {
                ...state,
                recipe: {
                    ...state.recipe,
                    elements: [...(state.recipe.elements || []), action.element],
                },
            };
        }
        case ADD_RECIPE: {
            return { ...state, recipe: action.recipe };
        }
        case EDIT_CATEGORY: {
            return { ...state, category: { ...state.category, ...action.category } };
        }
        case EDIT_ELEMENT: {
            const elements = state.recipe.elements.map((elt) => {
                if (elt.id === action.id || elt.key === action.id) {
                    return {
                        ...elt,
                        ...action.data,
                    };
                }

                return elt;
            });

            return { ...state, recipe: { ...state.recipe, elements } };
        }
        case EDIT_RECIPE: {
            return { ...state, recipe: { ...state.recipe, ...action.data } };
        }
        case REMOVE_ELEMENT: {
            const elements = state.recipe.elements.filter((element) => element.key !== action.key);

            return { ...state, recipe: { ...state.elements, elements } };
        }
        case SET_CATEGORIES: {
            return { ...state, categories: action.categories };
        }
        case SET_RECIPES: {
            return { ...state, recipes: action.recipes };
        }
        default:
            return state;
    }
};

export const MealsState = {
    categories: [],
    category: {},
    recipes: [],
    recipe: {},
};
