import { persistant } from '../Resources/Env';

export const IsJsonStringify = (str) => {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }

    return true;
};

export const removeStore = (key) => {
    if (persistant.includes(key)) {
        const store = getStore();

        if (store[key]) {
            delete store[key];

            localStorage.setItem('store', JSON.stringify(store));
        }
    } else {
        localStorage.removeItem(key);
    }
};

export const getItem = (key) => {
    return localStorage.getItem(key);
};

export const getStore = () => {
    return JSON.parse(localStorage.getItem('store') || '{}');
};

export const store = (data) => {
    const store = getStore();

    for (let [key, value] of Object.entries(data)) {
        if (persistant.includes(key)) {
            store[key] = IsJsonStringify(value) ? JSON.parse(value) : value;
            localStorage.setItem('store', JSON.stringify(store));
        } else {
            localStorage.setItem(key, typeof value === 'object' ? JSON.stringify(value) : value);
        }
    }
};
