import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import Paginator from 'react-hooks-paginator';
import Loader from './Loader';
import useStore from '@src/useStore';
import Locales from '@src/Utils/LocalesUtils';

const Pagination = (props) => {
    const pageLimit = props.limit || 10;
    const isLoading = props.isLoading || false;

    const { languages } = useStore();
    const [currentData, setCurrentData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [offset, setOffset] = useState(0);

    useEffect(() => {
        setCurrentData((props.data || []).slice(offset, offset + pageLimit));
    }, [props.data, offset, pageLimit]);

    /**
     * @param element
     * @returns {*}
     */
    const defaultTemplate = (element) => {
        return (
            <div key={element.id} className="row">
                <div className="col-md-2">{element.id}</div>
                <div className="col-md-6">{element.name}</div>
                <div className="col-md-4">{element.lastModified}</div>
            </div>
        );
    };

    /**
     * Affichage des données et de la pagination
     * @returns {*}
     */
    const renderPagination = () => {
        if (isLoading) {
            return <Loader mounted />;
        }

        if (!isEmpty(currentData)) {
            return (
                <>
                    {props.header ? props.header() : null}
                    {currentData.map((data) => (props.template ? props.template(data) : defaultTemplate(data)))}
                    <Paginator
                        totalRecords={props.data.length}
                        pageLimit={pageLimit}
                        pageNeighbours={2}
                        setOffset={setOffset}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                    />
                    <div className="clearfix" />
                </>
            );
        } else {
            return <div className="alert alert-info">{Locales._(languages, 'noData')}</div>;
        }
    };

    return <div className="paginator">{renderPagination()}</div>;
};

Pagination.propTypes = {
    data: PropTypes.array,
    header: PropTypes.func,
    isLoading: PropTypes.bool,
    limit: PropTypes.number,
    template: PropTypes.func,
};

export default Pagination;
