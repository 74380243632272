import React from 'react';
import { NavLink } from 'react-router-dom';
import { FaTimes } from 'react-icons/fa';
import PropTypes from 'prop-types';
import useStore from '@src/useStore';
import Locales from '@src/Utils/LocalesUtils';
import { modalOpen } from '@src/Actions/Modal';

const Shortcut = (props) => {
    const style = props.bg || 'primary';
    const Icon = props.icon || FaTimes;
    const route = props.route || 'dashboard';
    const Modal = props.modal || null;
    const { dispatch, languages, routes } = useStore();

    const renderTitles = () => {
        const render = [];

        if (props.subtitle) {
            render.push(
                <div key="subtitle" className={'text-xs font-weight-bold text-' + style + ' text-uppercase mb-1'}>
                    {Locales._(languages, props.subtitle)}
                </div>
            );
        }
        if (props.title) {
            render.push(
                <div key="title" className="h5 mb-0 font-weight-bold text-gray-800">
                    {Locales._(languages, props.title)}
                </div>
            );
        }

        return render;
    };

    const renderShortcut = () => {
        if (Modal) {
            return (
                <>
                    <div
                        className={'card shortcut ' + style + ' border-left-' + style + ' shadow cp h-100 py-2'}
                        onClick={() => dispatch(modalOpen(Modal.name))}>
                        <div className="card-body">
                            <div className="row no-gutters align-items-center">
                                <div className="col mr-2">{renderTitles()}</div>
                                <div className="col-auto">
                                    <Icon size="2.5em" className="text-gray-300" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <Modal.modal name={Modal.name} />
                </>
            );
        } else if (route) {
            return (
                <NavLink to={Locales.getRoute(routes, route)} className={'card shortcut ' + style + ' border-left-' + style + ' shadow h-100 py-2'}>
                    <div className="card-body">
                        <div className="row no-gutters align-items-center">
                            <div className="col mr-2">{renderTitles()}</div>
                            <div className="col-auto">
                                <Icon size="2.5em" className="text-gray-300" />
                            </div>
                        </div>
                    </div>
                </NavLink>
            );
        }
    };

    return renderShortcut();
};

Shortcut.propTypes = {
    bg: PropTypes.string,
    icon: PropTypes.func,
    modal: PropTypes.object,
    route: PropTypes.string,
    subtitle: PropTypes.string,
    title: PropTypes.string,
};

export default Shortcut;
