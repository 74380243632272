import React from 'react';
import Card from '../../Views/UI/Card';
import Shortcuts from './Elements/Shortcuts';

const Dashboard = () => {
    return (
        <>
            <Shortcuts />
            <div className="row">
                <div className="col-lg-6">
                    <Card title="meals.nextMeals">Vide</Card>
                </div>
                <div className="col-lg-6">
                    <Card title="meals.currentToDo">Vide</Card>
                </div>
            </div>
        </>
    );
};

export default Dashboard;
