import Noty from 'noty';

import 'noty/lib/noty.css';
import 'noty/lib/themes/relax.css';

/**
 * @see https://ned.im/noty/#/options
 */
const NotyUtils = {
    /**
     * @param {object} noty
     */
    noty: undefined,

    /**
     * @param params object
     * @private
     */
    _: (params) => {
        const defaultOptions = {
            closeWith: ['click'],
            layout: 'topRight',
            progressBar: false,
            timeout: 3000,
            /**
             * @see https://ned.im/noty/#/themes
             */
            theme: 'relax',
            type: 'info',
        };
        NotyUtils.noty = new Noty({
            ...defaultOptions,
            ...params,
        });
        NotyUtils._show();
    },
    /**
     * @private
     */
    _show: () => {
        NotyUtils.noty.show();
    },
    /**
     * @param text
     */
    error: (text) => {
        NotyUtils._({
            text,
            type: 'error',
        });
    },
    /**
     * @param text
     */
    info: (text) => {
        NotyUtils._({
            text,
            type: 'info',
        });
    },
    /**
     * @param text
     */
    success: (text) => {
        NotyUtils._({
            text,
            type: 'success',
        });
    },
    /**
     * @param text
     */
    warning: (text) => {
        NotyUtils._({
            text,
            type: 'warning',
        });
    },
};

export default NotyUtils;
