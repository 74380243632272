import React from 'react';
import { isEmpty } from 'lodash';
import { FaCartPlus } from 'react-icons/fa';
import useStore from '../../useStore';
import Locales from '../../Utils/LocalesUtils';
import DataUtils from '../../Utils/DataUtils';
import NotyUtils from "../../Utils/NotyUtils";
import Shortcuts from './Elements/Shortcuts';
import Title from '../../Views/UI/Title';
import EditRecipe from './Elements/EditRecipe';
import RecipesList from './Elements/Recipes';
import { editRecipe } from '../../Actions/Meals';

const Recipes = () => {
    const { dispatch, languages, state } = useStore();

    const createRecipe = () => {
        if (isEmpty(state.meals.categories || state.meals.category.id < 0)) {
            NotyUtils.info(Locales._(languages, 'meals.recipe.noCategory'));
        } else {
            dispatch(editRecipe(DataUtils.defaultRecipe()));
        }
    };

    const addRecipe = () => {
        return (
            <>
                <span onClick={createRecipe} className="d-none d-sm-inline-block btn btn-sm btn-secondary shadow-sm ml-2 cp">
                    <FaCartPlus /> {Locales._(languages, 'meals.recipe.addRecipe')}
                </span>
            </>
        );
    };

    return (
        <>
            <Title title={Locales._(languages, 'meals.recipe.title')} button={addRecipe} />
            <div className="row">
                <div className="col-5">
                    <RecipesList />
                </div>
                <div className="col-7">
                    <EditRecipe />
                </div>
            </div>
            <Shortcuts current="recipes" />
        </>
    );
};

Recipes.propTypes = {};

export default Recipes;
