import React from 'react';
import PropTypes from 'prop-types';
import languages from '../Resources/Locales/fr/languages';
import routes from '../Resources/Locales/fr/routes';

const Context = React.createContext();

const ContextProvider = (props) => {
    return <Context.Provider value={{ languages, routes }}>{props.children}</Context.Provider>;
};

ContextProvider.propTypes = {
    children: PropTypes.object,
};

export { Context, ContextProvider };
