import React, { useState } from 'react';
import { FaBars, FaEdit, FaTrash } from 'react-icons/fa';
import PropTypes from 'prop-types';

const Bar = (props) => {
    const { data, onDelete, onEdit } = props;
    const [open, setOpen] = useState(false);

    /**
     * Open Bar menu
     * @param e
     */
    const openBar = (e) => {
        e.preventDefault();

        if (open !== true) {
            setOpen(true);
            document.addEventListener('click', closeBar);
        }
    };

    /**
     * Close Bar menu
     */
    const closeBar = (e) => {
        if (e.target.classList.contains('logout') !== true) {
            if (props.history === undefined || (props.history && props.history.action !== 'PUSH')) {
                setOpen(false);
            }
            document.removeEventListener('click', closeBar);
        } else {
            setTimeout(() => {
                document.removeEventListener('click', closeBar);
            }, 250);
        }
    };

    const renderEdit = () => {
        if (onEdit !== undefined) {
            return (
                <button className="btn btn-primary btn-inverted btn-sm" onClick={() => onEdit(data)}>
                    <FaEdit />
                </button>
            );
        }

        return null;
    };

    const renderTrash = () => {
        if (onDelete !== undefined) {
            return (
                <button className="btn btn-danger btn-inverted btn-sm" onClick={() => onDelete(data)}>
                    <FaTrash />
                </button>
            );
        }

        return null;
    };

    return (
        <div className="toolbar">
            <button className="btn btn-info btn-sm" onClick={openBar}>
                <FaBars />
            </button>
            <div className={'toolbar-actions' + (open === true ? ' open' : '')}>
                <div className="btn-group" role="group">
                    {renderEdit()}
                    {renderTrash()}
                </div>
            </div>
        </div>
    );
};

Bar.propTypes = {
    data: PropTypes.object,
    history: PropTypes.object,
    onDelete: PropTypes.func,
    onEdit: PropTypes.func,
};

export default Bar;
