export const ADD = 'TRACKER:ADD';
export const REMOVE = 'TRACKER:REMOVE';

export const trackPromise = (name) => ({
    type: ADD,
    name,
});

export const unTrackPromise = (name) => ({
    type: REMOVE,
    name,
});
