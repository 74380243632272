import { LOGIN, LOGOUT } from '../Actions/User';
import { removeStore } from '../Store/Local';

export const UserReducer = (state, action) => {
    switch (action.type) {
        case LOGIN: {
            return { ...state, ...action.user };
        }
        case LOGOUT: {
            removeStore('expires');
            removeStore('token');
            removeStore('user');

            return {};
        }
        default:
            return state;
    }
};

export const UserState = {};
