import React, { cloneElement, isValidElement } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, isFunction, isString } from 'lodash';
import { useForm } from 'react-hook-form';
import useStore from '@src/useStore';
import ViewUtils from '@src/Utils/ViewUtils';
import Locales from '@src/Utils/LocalesUtils';

const Form = (props) => {
    const { languages } = useStore();
    const { errors, formState, handleSubmit, register } = useForm();
    const { isSubmitting } = formState;
    const supportedTypes = ['Input'];
    const childrens = props.children;
    const method = props.method || 'GET';

    const formParams = {
        className: ['form'].concat(props.classNames || []).join(' '),
        method,
    };

    if (props.onSubmit && isFunction(props.onSubmit)) {
        formParams['onSubmit'] = handleSubmit(props.onSubmit);
    }

    if (props.id && isString(props.id)) {
        formParams['id'] = props.id;
    }

    /**
     * Permet d'ajouter la fonction register dans les éléments Input
     * @param child
     * @returns {*}
     */
    const recursive = (child) => {
        if (isValidElement(child) && isFunction(child.type) && child.type.name.includes(supportedTypes)) {
            return cloneElement(child, { register: register(child.props.rules || {}), error: errors[child.props.name] || false });
        }

        return child;
    };

    const loading = () => {
        if (isSubmitting) {
            return (
                <>
                    <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true" /> {Locales._(languages, 'loading')}
                </>
            );
        } else {
            return Locales._(languages, 'users.form.okButton');
        }
    };

    const renderButtons = () => {
        if (isEmpty(props.buttons)) {
            return (
                <button className="btn btn-success" type="submit" disabled={isSubmitting}>
                    {loading()}
                </button>
            );
        }
    };

    return (
        <form {...formParams}>
            {ViewUtils.mapRecursive(childrens, recursive)}
            {renderButtons()}
        </form>
    );
};

Form.propTypes = {
    buttons: PropTypes.array,
    childrens: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    classNames: PropTypes.array,
    id: PropTypes.string,
    method: PropTypes.oneOf(['GET', 'POST']),
    onSubmit: PropTypes.func,
};

export default Form;
