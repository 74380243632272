import React, { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { NavLink } from 'react-router-dom';
import useStore from '../../../useStore';
import Locales from '../../../Utils/LocalesUtils';
import NotyUtils from '../../../Utils/NotyUtils';
import Card from '../../../Views/UI/Card';
import Editor from '../../../Views/UI/Editor';
import Meals from '../../../Services/Models/Meals';
import Elements from '../Elements/Elements';
import { addRecipe, editRecipe } from '../../../Actions/Meals';
import DataUtils from '../../../Utils/DataUtils';
import Loader from '../../../Views/Helpers/Loader';
import Form from '../../../Views/Helpers/Form';

const EditRecipe = () => {
    const { dispatch, languages, routes, state } = useStore();
    const addTitle = 'meals.recipe.addRecipe';
    const editTitle = 'meals.recipe.editRecipe';
    const [title, setTitle] = useState(addTitle);
    const [isMounted, setMounted] = useState(false);
    const [errors, setErrors] = useState({});

    useEffect(() => {
        Meals.getCategories({ dispatch })
            .then(() => setMounted(true))
            .catch(() => setMounted(true));
        dispatch(addRecipe(DataUtils.defaultRecipe()));
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (state.meals.recipe.id > 0) {
            setTitle(editTitle);
        } else {
            setTitle(addTitle);
        }
    }, [state.meals.recipe]);

    /**
     * Liste des options day_time
     * @returns {*[]}
     */
    const getDayTimeOptions = () => {
        return DataUtils.dayTimeOptions.map((opt) => ({
            id: opt.value,
            title: Locales._(languages, opt.name)
        }));
    };

    const submit = (e) => {
        e.preventDefault();

        const recipe = state.meals.recipe;
        Meals.editRecipe({ data: recipe, dispatch }).then((response) => {
            if (response.errors) {
                NotyUtils.error(response.message);

                setErrors(response.errors);
            } else {
                setErrors({});
            }
            Meals.getRecipes({ dispatch }).then((r) => r);
        });
    };

    const onChange = (e) => {
        dispatch(editRecipe({ [e.target.name]: e.target.value }));
    };

    const onChangeDescription = (value) => {
        dispatch(
            editRecipe({
                ...state.meals.recipe,
                content: value,
            })
        );
    };

    const renderComponent = () => {
        if (!isMounted) {
            return (
                <>
                    <Card title={title}>
                        <Loader mounted />
                    </Card>
                </>
            );
        }

        return (
            <>
                <Loader name="meals.editRecipe" wrapper />
                <Card title={title}>
                    {isEmpty(state.meals.categories) ? (
                        <>
                            <NavLink to={Locales.getRoute(routes, 'meals.category')} className="btn btn-warning">
                                {Locales._(languages, 'meals.category.addCategory')}
                            </NavLink>
                        </>
                    ) : (
                        <form method="POST" className="recipe" onSubmit={submit}>
                            <div className="form-group">
                                <label htmlFor="recipeTitle">{Locales._(languages, 'meals.recipe.form.name')}</label>
                                <Form
                                    id="recipeTitle"
                                    type="text"
                                    name="title"
                                    placeholder="meals.recipe.form.name"
                                    value={state.meals.recipe.title}
                                    onChange={onChange}
                                    hasError={errors.title || false}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="recipeDescription">{Locales._(languages, 'meals.recipe.form.description')}</label>
                                <Editor value={state.meals.recipe.content} onChange={onChangeDescription} errors={errors.content || false} />
                            </div>
                            <fieldset>
                                <legend>{Locales._(languages, 'meals.recipe.form.elements.legend')}</legend>
                                <Elements errors={errors.elements || false} />
                            </fieldset>
                            <div className="form-group">
                                <label htmlFor="recipeJourney">{Locales._(languages, 'meals.recipe.form.day_time.title')}</label>
                                <Form
                                    id="recipeJourney"
                                    type="select"
                                    name="journey"
                                    options={getDayTimeOptions()}
                                    value={state.meals.recipe.journey}
                                    onChange={onChange}
                                    hasError={errors.journey || false}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="recipeWeight">{Locales._(languages, 'meals.recipe.form.weighting')}</label>
                                <Form
                                    id="recipeWeight"
                                    type="number"
                                    name="weight"
                                    placeholder="meals.recipe.form.weighting"
                                    value={state.meals.recipe.weight}
                                    onChange={onChange}
                                    hasError={errors.weight || false}
                                />
                                <small className="form-text text-muted">{Locales._(languages, 'meals.recipe.form.weightingExplain')}</small>
                            </div>
                            <button className="btn btn-success" type="submit">
                                {Locales._(languages, title)}
                            </button>
                        </form>
                    )}
                </Card>
            </>
        );
    };

    return renderComponent();
};

EditRecipe.propTypes = {};

export default EditRecipe;
