import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
// import Candlestick from 'react-candlestick';
import useStore from '@src/useStore';
import { usersEdit, usersUpdate } from '@src/Actions/User';
import UserModel from '@src/Services/Models/User';
import Locales from '@src/Utils/LocalesUtils';
import NotyUtils from '@src/Utils/NotyUtils';
import Card from '@src/Views/UI/Card';
import Form from '@src/Views/Helpers/Form';
import Input from '@src/Views/Helpers/Input';
import Loader from '@src/Views/Helpers/Loader';
import { rules } from '@src/Validations/user';

const Edit = (props) => {
    const { history } = props;
    const { dispatch, languages, routes, state } = useStore();
    const [isMounted, setMounted] = useState(false);
    const userId = (props.match && props.match.params && props.match.params.userId) || 0;
    const isEditMode = userId > 0;
    const user = state.users.edit || {};

    useEffect(() => {
        if (!isEditMode) {
            // Création d'un utilisateur
            setMounted(true);
            dispatch(usersEdit({}));
        } else {
            // Modification d'un utilisateur
            UserModel.getUser({
                userId,
                onSuccess: (data) => {
                    const user = data.response;
                    if (!user.id) {
                        NotyUtils.error(Locales.getLocale(languages, 'users.noUser'));
                        history.push(Locales.getRoute(routes, 'users.list'));
                    } else {
                        dispatch(usersEdit(user));
                        setMounted(true);
                    }
                },
                onError: () => {
                    setMounted(true);
                },
            });
        }
        // eslint-disable-next-line
    }, []);

    const onErrors = (errors) => {
        console.log('onErrors', errors);
    };

    const onSubmit = (data) => {
        let promise = UserModel.addUser;
        if (isEditMode) {
            promise = UserModel.setUser;
        }

        return promise({
            onError: (error) => {
                NotyUtils.error(error.response.message);
            },
            onSuccess: (data) => {
                dispatch(usersUpdate(user));
                NotyUtils.success(data.response.message);
            },
            user: data
        });
    };

    const renderComponent = () => {
        if (!isMounted) {
            return (
                <>
                    <Card title={isEditMode ? 'users.edit.title' : 'users.create.title'} tokens={{ '{username}': user.firstName }}>
                        <Loader mounted />
                    </Card>
                </>
            );
        }

        return (
            <>
                <Card title={isEditMode ? 'users.edit.title' : 'users.create.title'} tokens={{ '{username}': user.firstName }}>
                    <Form method="POST" className={['users-edit']} onSubmit={onSubmit} onErrors={onErrors}>
                        <div className="form-group">
                            <label htmlFor="mail">{Locales._(languages, 'users.form.mail')}</label>
                            <Input
                                id="mail"
                                type="email"
                                name="mail"
                                placeholder="users.form.mail"
                                value={user.mail}
                                rules={rules.mail}
                            />
                        </div>
                        <div className="form-group">
                            <div className="row">
                                <div className="col">
                                    <label htmlFor="firstName">{Locales._(languages, 'users.form.firstname')}</label>
                                    <Input
                                        id="firstName"
                                        type="text"
                                        name="firstName"
                                        placeholder="users.form.firstname"
                                        value={user.firstName}
                                        rules={rules.firstName}
                                    />
                                </div>
                                <div className="col">
                                    <label htmlFor="lastName">{Locales._(languages, 'users.form.lastname')}</label>
                                    <Input
                                        id="lastName"
                                        type="text"
                                        name="lastName"
                                        placeholder="users.form.lastname"
                                        value={user.lastName}
                                        rules={rules.lastName}
                                    />
                                </div>
                            </div>
                        </div>
                        {isEditMode ? <Input name="id" type="hidden" value={user.id} rules={{required: true}} /> : null}
                        {/*<div className="form-group">*/}
                        {/*    <label className="mr-2" htmlFor="status">*/}
                        {/*        {Locales._(languages, 'users.form.status')}*/}
                        {/*    </label>*/}
                        {/*    <Candlestick*/}
                        {/*        defaultValue={user.active ? 1 : 0}*/}
                        {/*        name="status"*/}
                        {/*        onChange={(value) => dispatch(usersEdit({ ...user, active: value }))}*/}
                        {/*    />*/}
                        {/*</div>*/}
                    </Form>
                </Card>
            </>
        );
    };

    return renderComponent();
};

Edit.propTypes = {
    history: PropTypes.object,
    match: PropTypes.object,
};

export default Edit;
