import Api from '../Api';
import { trackPromise, unTrackPromise } from '../../Actions/Tracker';
import { setCategories, setRecipes } from '../../Actions/Meals';

const Meals = {
    getCategories: ({ dispatch }) => {
        dispatch(trackPromise('meals.getCategories'));

        return Api.getApiPromise({
            request: ['meals', 'categories'],
        }).then((data) => {
            dispatch(setCategories(data.categories));
            dispatch(unTrackPromise('meals.getCategories'));

            return data.categories;
        });
    },

    editCategory: (data, callbackSuccess, callbackError) => {
        const request = ['meals', 'categories'];
        let method = 'POST';
        if (parseInt(data.id) > 0) {
            request.push(data.id);
            method = 'PATCH';
        } else {
            delete data.id;
        }

        return Api.getApiPromise({
            method,
            data,
            request,
            callbackSuccess,
            callbackError,
        });
    },

    removeCategory: (id) => {
        const request = ['meals', 'categories', parseInt(id)];
        let method = 'DELETE';

        return Api.getApiPromise({
            method,
            request,
        });
    },

    getRecipes: ({ dispatch, onSuccess }) => {
        dispatch(trackPromise('meals.getRecipes'));

        return Api.getApiPromise({
            request: ['meals', 'recipes'],
            callbackSuccess: onSuccess
        }).then((data) => {
            if (data.recipes) {
                dispatch(setRecipes(data.recipes));
            }
            dispatch(unTrackPromise('meals.getRecipes'));

            return data.recipes;
        });
    },

    editRecipe: ({ data, dispatch }) => {
        dispatch(trackPromise('meals.editRecipe'));
        const request = ['meals', 'recipes'];
        let method = 'POST';
        if (parseInt(data.id) > 0) {
            request.push(data.id);
            method = 'PATCH';
        } else {
            delete data.id;
        }

        return Api.getApiPromise({
            method,
            data,
            request,
        }).then((data) => {
            dispatch(unTrackPromise('meals.editRecipe'));

            return data;
        });
    },

    removeRecipe: (id) => {
        const request = ['meals', 'recipes', parseInt(id)];
        let method = 'DELETE';

        return Api.getApiPromise({
            method,
            request,
        });
    },
};

export default Meals;
