import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Loader from '../Helpers/Loader';

const Blank = (props) => {
    const [isMounted, setMounted] = useState(false);

    useEffect(() => {
        setMounted(true);
    }, []);

    return isMounted ? <div id="blank-layout">{props.children}</div> : <Loader large />;
};

Blank.propTypes = {
    children: PropTypes.object,
};

export default Blank;
