import React from 'react';
import { deepMap } from 'react-children-utilities';
import Locales from './LocalesUtils';

const ViewUtils = {
    getStatus: ({ data, isLoading, languages, onChange, status }) => {
        if (isLoading) {
            return (
                <button className={"btn " + (status ? "btn-primary" : "btn-danger")} type="button" disabled>
                    <div className="spinner-border spinner-border-sm" role="status" />
                </button>
            );
        }

        if (status) {
            return (
                <button className="btn btn-primary" onClick={() => onChange({ status: 0, data })}>
                    {Locales._(languages, 'status.activate')}
                </button>
            );
        } else {
            return (
                <button className="btn btn-danger" onClick={() => onChange({ status: 1, data })}>
                    {Locales._(languages, 'status.deactivate')}
                </button>
            );
        }
    },

    mapRecursive: (childrens , callback) => {
        return deepMap(childrens, callback);
    }
};

export default ViewUtils;
