import React from 'react';
import useStore from '@src/useStore';
import Locales from '@src/Utils/LocalesUtils';
import Modal from '@src/Views/UI/Modal';
import { ajaxFindGame } from '@components/Games/BoardGames/Utils';
import StringUtils from '@src/Utils/StringUtils';
import { findGame } from '@src/Actions/Boardgames';
import ResultGame from '@components/Games/BoardGames/Modals/ResultGame';
import { modalOpen } from '@src/Actions/Modal';

const FindGame = () => {
    const name = 'boargames.findGame';
    const { dispatch, languages, state } = useStore();

    /**
     * @returns {*[]}
     */
    const formatCategoriesOptions = () => {
        const options = [];
        const types = {};

        (state.boardgames.categories || []).map((category) => {
            const theme = category.type || 'category';

            if (!types[theme]) {
                types[theme] = [];
            }

            types[theme].push(category);

            return category;
        });

        Object.keys(types)
            .sort()
            .map((type) => {
                options.push(
                    <optgroup key={type} label={Locales._(languages, 'games.boardgame.categories.' + type)}>
                        {types[type].sort(StringUtils.compareObjectByName).map((t) => {
                            return (
                                <option key={t.id} value={t.id}>
                                    {t.title}
                                </option>
                            );
                        })}
                    </optgroup>
                );

                return [];
            });

        return options;
    };

    /**
     * @returns
     */
    const confirmModal = (close) => {
        const checked = document.querySelectorAll('#boardgame-selectFindGame :checked');
        const categories = [...checked].map((option) => option.value);

        ajaxFindGame({ categories })
            .then((data) => {
                dispatch(findGame(data));
                dispatch(modalOpen('boargames.resultGame'));
                close();
            })
            .catch(() => {
                close();
            });
    };

    return (
        <>
            <ResultGame />
            <Modal
                key="boardgame.modalFindGame"
                name={name}
                title={Locales._(languages, 'games.boardgame.modalFindGame.title')}
                onConfirm={confirmModal}>
                <div className="form-group">
                    <select id="boardgame-selectFindGame" className="form-control" name="categories" multiple>
                        {formatCategoriesOptions()}
                    </select>
                </div>
            </Modal>
        </>
    );
};

FindGame.propTypes = {};

export default FindGame;
