import { CLOSE, OPEN } from '../Actions/Modal';

export const ModalReducer = (state, action) => {
    switch (action.type) {
        case CLOSE: {
            const modals = state.modals.filter((modal) => modal.name !== action.name);

            return { ...state, modals };
        }
        case OPEN: {
            const modals = [...state.modals, { name: action.name, opened: true }];

            return { ...state, modals };
        }
        default:
            return state;
    }
};

export const ModalState = {
    modals: [],
};
