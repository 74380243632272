import { ADD, REMOVE } from '../Actions/Tracker';

export const TrackerReducer = (state, action) => {
    switch (action.type) {
        case ADD: {
            const promises = [...state.promises, { name: action.name, loading: true }];

            return { ...state, promises };
        }
        case REMOVE: {
            const promises = state.promises.map((promise) => {
                if (promise.name === action.name) {
                    return { ...promise, loading: false };
                }

                return promise;
            });

            return { ...state, promises };
        }
        default:
            return state;
    }
};

export const TrackerState = {
    promises: [],
};
