import React, { useState} from 'react';
import Select from 'react-select';
import useStore from "@src/useStore";
import Boardgames from '@src/Services/Models/Boardgames';
import Locales from "@src/Utils/LocalesUtils";
import NotyUtils from "@src/Utils/NotyUtils";
import Modal from "@src/Views/UI/Modal";

const AddGame = () => {
    const name = 'boargames.addGame';
    const { languages, state } = useStore();
    const [game, setGame] = useState({value: 0, label: Locales._(languages, 'games.boardgame.modalAddGame.placeholderOption')});

    /**
     * @returns
     */
    const confirmModal = (close) => {
        Boardgames.addPart({
            gameId: game.value,
            onError: (response) => {
                NotyUtils.error(response.response.message);
                close();
            },
            onSuccess: (response) => {
                NotyUtils.success(response.response.message);
                setGame({});
                close();
            }
        });
    };

    /**
     * @returns {{label: *, value: *}[]}
     */
    const getOptions = () => {
        return state.boardgames.games.map((game) => {
            return {
                value: game.id,
                label: game.title
            };
        })
    };

    return (
        <>
            <Modal
                key="boardgame.modalAddGame"
                name={name}
                title={Locales._(languages, 'games.boardgame.modalAddGame.title')}
                onConfirm={confirmModal}>
                <div className="form-group">
                    <Select defaultValue={game} onChange={(value) => setGame(value)} options={getOptions()} />
                </div>
            </Modal>
        </>
    );
};

export default AddGame;
