export const CLOSE = 'MODAL:CLOSE';
export const OPEN = 'MODAL:OPEN';

export const modalClose = (name) => ({
    type: CLOSE,
    name,
});

export const modalOpen = (name) => ({
    type: OPEN,
    name,
});
