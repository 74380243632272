import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { FaBell, FaFileAlt } from 'react-icons/fa';
import { Context } from '@src/Store/Context';
import Locales from '@src/Utils/LocalesUtils';
import Dropdown from '@src/Views/UI/Dropdown';
import Search from '@src/Views/Elements/Search';
import Profile from '@src/Views/Elements/Profile';

const NavBar = () => {
    const { languages, routes } = useContext(Context);

    return (
        <div className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
            <button id="sidebarToggleTop" className="btn btn-link d-md-none rounded-circle mr-3">
                <i className="fa fa-bars" />
            </button>

            <Search />

            <ul className="navbar-nav ml-auto">
                <li className="nav-item dropdown no-arrow mx-1">
                    <Dropdown
                        isList
                        button={() => {
                            return (
                                <>
                                    <FaBell /> <span className="badge badge-danger badge-counter">3+</span>
                                </>
                            );
                        }}>
                        <h6 className="dropdown-header">Alerts Center</h6>
                        <NavLink to={Locales.getRoute(routes, 'logout')} className="dropdown-item d-flex align-items-center">
                            <div className="mr-3">
                                <div className="icon-circle bg-primary">
                                    <FaFileAlt className="text-white" />
                                </div>
                            </div>
                            <div>
                                <div className="small text-gray-500">December 12, 2019</div>
                                <span className="font-weight-bold">A new monthly report is ready to download!</span>
                            </div>
                        </NavLink>
                        <NavLink to={Locales.getRoute(routes, 'logout')} className="dropdown-item d-flex align-items-center">
                            <div className="mr-3">
                                <div className="icon-circle bg-primary">
                                    <FaFileAlt className="text-white" />
                                </div>
                            </div>
                            <div>
                                <div className="small text-gray-500">December 12, 2019</div>
                                <span className="font-weight-bold">A new monthly report is ready to download!</span>
                            </div>
                        </NavLink>
                        <NavLink to={Locales.getRoute(routes, 'logout')} className="dropdown-item text-center small text-gray-500">
                            {Locales._(languages, 'notifications.more')}
                        </NavLink>
                    </Dropdown>
                </li>

                <div className="topbar-divider d-none d-sm-block" />

                <li className="nav-item dropdown no-arrow">
                    <Profile />
                </li>
            </ul>
        </div>
    );
};

export default NavBar;
