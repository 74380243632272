export const CHANGE_LOADING = 'BOARDGAMES:CHANGE_LOADING';
export const FIND_GAME = 'BOARDGAMES:FIND_GAME';
export const SET_GAMES = 'BOARDGAMES:SET_GAMES';
export const UPDATE_LIST = 'BOARDGAMES:UPDATE_LIST';

export const changeLoadingStatus = (gameId, loading) => ({
    type: CHANGE_LOADING,
    gameId,
    loading
});

export const findGame = (game) => ({
    type: FIND_GAME,
    game,
});
export const setGames = (games) => ({
    type: SET_GAMES,
    games
});

export const gameUpdate = (game) => ({
    type: UPDATE_LIST,
    game
});
