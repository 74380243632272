import React, { useEffect, useState } from 'react';
import Locales from '../../../Utils/LocalesUtils';
import Modal from '../../../Views/UI/Modal';
import Meals from '../../../Services/Models/Meals';
import NotyUtils from '../../../Utils/NotyUtils';
import DataUtils from '../../../Utils/DataUtils';
import useStore from '../../../useStore';
import { editCategory } from '../../../Actions/Meals';
import Loader from '../../../Views/Helpers/Loader';
import Form from '../../../Views/Helpers/Form';
import { trackPromise, unTrackPromise } from '../../../Actions/Tracker';

const EditCategory = () => {
    const { dispatch, languages, state } = useStore();
    const addTitle = 'meals.category.addCategory';
    const editTitle = 'meals.category.editCategory';
    const [title, setTitle] = useState(addTitle);
    const [errors, setErrors] = useState({});

    useEffect(() => {
        dispatch(unTrackPromise('meals.modal.editCategory'));
        setErrors({});
        if (state.meals.category && state.meals.category.id) {
            setTitle(editTitle);
        } else {
            setTitle(addTitle);
        }
        // eslint-disable-next-line
    }, [state.meals.category]);

    /**
     * Validation de la fenêtre modal
     * @param close
     */
    const confirm = (close) => {
        dispatch(trackPromise('meals.modal.editCategory'));

        Meals.editCategory(
            state.meals.category,
            (data) => {
                if (data && data.message) {
                    NotyUtils.success(data.message);
                }
                dispatch(editCategory(DataUtils.defaultMealCategory()));
                Meals.getCategories({ dispatch }).then((r) => r);
                close();
            },
            (e) => {
                dispatch(unTrackPromise('meals.modal.editCategory'));
                NotyUtils.error(e.response.message);
                if (e.response.errors) {
                    setErrors(e.response.errors);
                }
            }
        );
    };

    /**
     * Modification du nom de la catégorie
     * @param e
     */
    const edit = (e) => {
        dispatch(
            editCategory({
                title: e.target.value,
            })
        );
    };

    /**
     * Fermeture de la fenêtre modal
     */
    const close = () => {
        setTitle(addTitle);
        dispatch(editCategory(DataUtils.defaultMealCategory()));
    };

    return (
        <Modal name="meals.addCategory" title={Locales._(languages, title)} onConfirm={confirm} onClose={close}>
            <Loader name="meals.modal.editCategory" wrapper />
            <Form value={state.meals.category.title} placeholder="meals.category.placeholder" onChange={edit} hasError={errors.title || false} />
        </Modal>
    );
};

EditCategory.propTypes = {};

export default EditCategory;
