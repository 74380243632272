import StringUtils from './StringUtils';

const DataUtils = {
    defaultMealCategory: () => {
        return {
            id: 0,
            title: '',
        };
    },

    defaultRecipe: () => {
        return {
            id: 0,
            content: '',
            elements: [
                {
                    key: StringUtils.randomKey(),
                    category: 0,
                    title: '',
                    qty: '',
                    type: '',
                },
            ],
            journey: 1,
            publish: 1,
            title: '',
            weight: 10,
        };
    },

    dayTimeOptions: [
        { name: 'meals.recipe.form.day_time.options.noon', value: 1 },
        { name: 'meals.recipe.form.day_time.options.evening', value: 2 },
        { name: 'meals.recipe.form.day_time.options.both', value: 3 },
    ],

    unitOptions: [
        { name: 'meals.recipe.form.elements.type', value: '' },
        { name: 'meals.recipe.form.elements.types.g', value: 'gramme' },
        { name: 'meals.recipe.form.elements.types.kg', value: 'kg' },
        { name: 'meals.recipe.form.elements.types.l', value: 'litre' },
        { name: 'meals.recipe.form.elements.types.other', value: 'other' },
    ],
};

export default DataUtils;
