import React from 'react';
import { StoreProvider } from './Store/Store';
import { ContextProvider } from './Store/Context';
import StandardRouter from './Routes/StandardRouter';

const Application = () => {
    return (
        <StoreProvider>
            <ContextProvider>
                <StandardRouter />
            </ContextProvider>
        </StoreProvider>
    );
};

export default Application;
