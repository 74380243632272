import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { NavLink } from 'react-router-dom';
import { GiPerspectiveDiceSixFacesRandom } from 'react-icons/gi';
import { RiFileAddFill } from 'react-icons/ri';
import moment from 'moment';
import useStore from '@src/useStore';
import { changeLoadingStatus, setGames, gameUpdate } from '@src/Actions/Boardgames';
import Boardgames from '@src/Services/Models/Boardgames';
import Locales from '@src/Utils/LocalesUtils';
import Noty from '@src/Utils/NotyUtils';
import ViewUtils from '@src/Utils/ViewUtils';
import DiffUtils from '@src/Utils/DiffUtils';
import Bar from '@src/Views/UI/Bar';
import Pagination from '@src/Views/Helpers/Pagination';
import Card from '@src/Views/UI/Card';
import FindGame from '@components/Games/BoardGames/Modals/FindGame';
import AddGame from '@components/Games/BoardGames/Modals/AddGame';
import { modalOpen } from '@src/Actions/Modal';

const Dashboard = (props) => {
    const { dispatch, languages, routes, state } = useStore();
    const [isLoading, setLoading] = useState(true);

    useEffect(() => {
        Boardgames.getGames({
            limit: -1,
            onError: (response) => {
                setLoading(false);
                console.log('onError', response);
            },
            onSuccess: (response) => {
                dispatch(setGames(response.response.games));
                setLoading(false);
            },
        });
        // eslint-disable-next-line
    }, []);

    /**
     * Modification de l'élément
     * @param game
     */
    const onEditElement = (game) => {
        console.log(game);
        // const route = Locales.getRoute(routes, 'users.edit', { userId: user.id });
        // props.history.push(route);
    };

    /**
     * Suppression de l'élément
     * @param game
     */
    const onDeleteElement = (game) => {
        console.log(game);
    };

    /**
     * Lors de la modification du statut
     * @param {object} data
     * @param {integer} status
     */
    const onChangeStatus = ({ data, status }) => {
        const newData = DiffUtils.deepMapper().filter({ ...data, publish: status }, data);

        if (!isEmpty(newData)) {
            const game = {
                ...newData,
                id: data.id,
            };
            const gameId = game.id;
            dispatch(changeLoadingStatus(gameId, true));

            Boardgames.setGame({
                game,
                onError: (error) => {
                    Noty.error(error.response.message);
                    dispatch(changeLoadingStatus(gameId, false));
                },
                onSuccess: (response) => {
                    Noty.success(response.response.message);
                    dispatch(gameUpdate({ ...game, id: gameId, isLoading: false }));
                },
            });
        }
    };

    /**
     * Affichage du header
     * @returns {*}
     */
    const getHeader = () => {
        return (
            <div key="header" className="row row-header">
                <div className="col-md-1">{Locales._(languages, 'games.boardgame.table.header.id')}</div>
                <div className="col-md-4">{Locales._(languages, 'games.boardgame.table.header.name')}</div>
                <div className="col-md-4">{Locales._(languages, 'games.boardgame.table.header.categories')}</div>
                <div className="col-md-1">{Locales._(languages, 'games.boardgame.table.header.active')}</div>
                <div className="col-md-2">{Locales._(languages, 'games.boardgame.table.header.lastModified')}</div>
            </div>
        );
    };

    /**
     * Affichage d'une ligne
     * @param element
     * @returns {*}
     */
    const getElement = (element) => {
        return (
            <div key={element.id} className="row row-element mt-1">
                <div className="col-md-1">
                    <Bar history={props.history} data={element} onEdit={onEditElement} onDelete={onDeleteElement} />
                </div>
                <div className="col-md-4">
                    <span className="d-sm-none">{Locales._(languages, 'games.boardgame.table.header.name')}&nbsp;</span>
                    {element.title}
                </div>
                <div className="col-md-4">
                    <span className="d-sm-none">{Locales._(languages, 'games.boardgame.table.header.categories')}&nbsp;</span>
                    {formatCategories(element.categories)}
                </div>
                <div className="col-md-1 is-status">
                    {ViewUtils.getStatus({
                        data: element,
                        isLoading: element.isLoading,
                        languages,
                        status: element.publish,
                        onChange: onChangeStatus,
                    })}
                </div>
                <div className="col-md-2">
                    <span className="d-sm-none">{Locales._(languages, 'games.boardgame.table.header.lastModified')}&nbsp;</span>
                    {moment(element.updated_date).format('DD-MM-YYYY HH:mm:ss')}
                </div>
            </div>
        );
    };

    /**
     * @param {[]} categories
     * @returns {*[]}
     */
    const formatCategories = (categories) => {
        const categoryNames = (categories || []).map((category) => {
            return (
                <li key={category.id} className="list-inline-item">
                    <NavLink className="btn btn-xs-primary" to={Locales.getRoute(routes, 'games.boardgame.category', { categoryId: category.id })}>
                        <span>{category.title}</span>
                    </NavLink>
                </li>
            );
        });

        return <ul className="list-inline list-categories">{categoryNames}</ul>;
    };

    /**
     * Ouverture de la modal de recherche de jeu
     */
    const getRandomGame = () => {
        dispatch(modalOpen('boargames.findGame'));
    };

    /**
     * Ouverture de la modal d'ajout d'une partie d'un jeu
     */
    const addPartyGame = () => {
        dispatch(modalOpen('boargames.addGame'));
    };

    return (
        <>
            <Card
                title={'games.boardgame.table.title'}
                dropdown={() => (
                    <>
                        <span className="dropdown-item cp" onClick={getRandomGame}>
                            <GiPerspectiveDiceSixFacesRandom /> {Locales._(languages, 'games.boardgame.findGame')}
                        </span>
                        <span className="dropdown-item cp" onClick={addPartyGame}>
                            <RiFileAddFill /> {Locales._(languages, 'games.boardgame.addGame')}
                        </span>
                    </>
                )}>
                <AddGame />
                <FindGame />
                <Pagination
                    isLoading={isLoading}
                    header={getHeader}
                    template={getElement}
                    data={(state.boardgames && state.boardgames.games) || []}
                    limit={25}
                />
            </Card>
        </>
    );
};

Dashboard.propTypes = {
    history: PropTypes.object,
};

export default Dashboard;
