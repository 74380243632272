import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Context } from '../../../Store/Context';
import Locales from '../../../Utils/LocalesUtils';
import Modal from '../../../Views/UI/Modal';

const Menu = (props) => {
    const name = props.name || 'default';
    const { languages } = useContext(Context);

    const generateMenu = (close) => {
        alert('generateMenu');

        setTimeout(close, 2000);
    };

    return (
        <Modal name={name} title={Locales._(languages, 'meals.menu.title')} onConfirm={generateMenu}>
            <p>{Locales._(languages, 'meals.menu.modal.description')}</p>
        </Modal>
    );
};

Menu.propTypes = {
    name: PropTypes.string,
};

export default Menu;
