import React from 'react';
import { NavLink } from 'react-router-dom';
import { FaUserAlt } from 'react-icons/fa';
import { FiLogOut } from 'react-icons/fi';
import useStore from '@src/useStore';
import Locales from '@src/Utils/LocalesUtils';
import Dropdown from '../UI/Dropdown';

const Profile = () => {
    const { languages, routes, state } = useStore();

    return (
        <div className="dropdown-profile">
            <Dropdown button={(state && state.user && state.user.firstName) || ''}>
                <NavLink to={Locales.getRoute(routes, 'profile')} className="dropdown-item nav-icon">
                    <FaUserAlt />
                    <span>{Locales.getLocale(languages, 'menu.profile')}</span>
                </NavLink>
                <div className="dropdown-divider" />
                <NavLink to={Locales.getRoute(routes, 'logout')} className="dropdown-item nav-icon">
                    <FiLogOut />
                    <span className="logout">{Locales.getLocale(languages, 'menu.logout')}</span>
                </NavLink>
            </Dropdown>
        </div>
    );
};

export default Profile;
