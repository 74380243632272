import React, { useContext, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { FaChevronLeft, FaChevronRight, FaTachometerAlt, FaFantasyFlightGames } from 'react-icons/fa';
import { FiChevronsLeft, FiUsers, FiUserPlus } from 'react-icons/fi';
import { GiMeal } from 'react-icons/gi';
import { Context } from '@src/Store/Context';
import Locales from '@src/Utils/LocalesUtils';

const Sidebar = () => {
    const { languages, routes } = useContext(Context);
    const [isToggled, setToggle] = useState(false);

    const toggleSidebar = (e) => {
        e.preventDefault();

        setToggle(!isToggled);
    };

    return (
        <div className={'navbar-nav bg-gradient-primary sidebar sidebar-dark accordion' + (isToggled ? ' toggled' : '')} id="accordionSidebar">
            <NavLink to={Locales.getRoute(routes, 'dashboard')} className="sidebar-brand d-flex align-items-center justify-content-center">
                <div className="sidebar-brand-icon">
                    <FiChevronsLeft size="2.5em" />
                </div>
                <div className="sidebar-brand-text mx-3">{Locales._(languages, 'brand')}</div>
            </NavLink>

            <hr className="sidebar-divider my-0" />

            <div className="nav-item nav-icon">
                <NavLink to={Locales.getRoute(routes, 'dashboard')} className="nav-link">
                    <FaTachometerAlt />
                    <span>{Locales._(languages, 'menu.dashboard')}</span>
                </NavLink>
            </div>

            <hr className="sidebar-divider" />

            <div className="sidebar-heading">{Locales._(languages, 'users.menu')}</div>

            <div className="nav-item nav-icon">
                <NavLink to={Locales.getRoute(routes, 'users.list')} className="nav-link">
                    <FiUsers />
                    <span>{Locales._(languages, 'menu.users')}</span>
                </NavLink>
                <NavLink to={Locales.getRoute(routes, 'users.add')} className="nav-link">
                    <FiUserPlus />
                    <span>{Locales._(languages, 'menu.addUser')}</span>
                </NavLink>
            </div>

            <hr className="sidebar-divider" />

            <div className="sidebar-heading">{Locales._(languages, 'plugins')}</div>

            <div className="nav-item nav-icon">
                <NavLink to={Locales.getRoute(routes, 'meals')} className="nav-link">
                    <GiMeal />
                    <span>{Locales._(languages, 'menu.meals')}</span>
                </NavLink>
            </div>

            <div className="nav-item nav-icon">
                <NavLink to={Locales.getRoute(routes, 'games.boardgame.dashboard')} className="nav-link">
                    <FaFantasyFlightGames />
                    <span>{Locales._(languages, 'games.boardgame.menu')}</span>
                </NavLink>
            </div>

            <hr className="sidebar-divider d-none d-md-block" />

            <div className="text-center d-none d-md-inline">
                <button className="rounded-circle" onClick={toggleSidebar}>
                    {isToggled ? <FaChevronRight /> : <FaChevronLeft />}
                </button>
            </div>
        </div>
    );
};

export default Sidebar;
