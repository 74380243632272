import { BoardgamesReducer, BoardgamesState } from '@reducers/Boardgames';
import { MealsReducer, MealsState } from '@reducers/Meals';
import { ModalReducer, ModalState } from '@reducers/Modal';
import { TrackerReducer, TrackerState } from '@reducers/Tracker';
import { UserReducer, UserState } from '@reducers/User';
import { UsersReducer, UsersState } from '@reducers/Users';
import { reducer } from '@src/Utils/Logger/logger';

const combineReducers = (reducers) => (state, action) => {
    let hasChanged;

    const nextState = Object.keys(reducers).reduce((result, key) => {
        result[key] = reducers[key](state[key], action);
        hasChanged = hasChanged || result[key] !== state[key];

        return result;
    }, {});
    reducer({ state, action, nextState });

    return hasChanged ? nextState : state;
};

export const rootReducer = combineReducers({
    boardgames: BoardgamesReducer,
    meals: MealsReducer,
    modal: ModalReducer,
    tracker: TrackerReducer,
    user: UserReducer,
    users: UsersReducer,
});

export const initialState = {
    boardgames: BoardgamesState,
    meals: MealsState,
    modal: ModalState,
    tracker: TrackerState,
    user: UserState,
    users: UsersState,
};
