export const ADD_ELEMENT = 'MEAL:ELEMENTS:ADD';
export const EDIT_CATEGORY = 'MEAL:CATEGORIES:EDIT';
export const EDIT_ELEMENT = 'MEAL:ELEMENTS:EDIT';
export const EDIT_RECIPE = 'MEAL:RECIPE:EDIT';
export const REMOVE_ELEMENT = 'MEAL:ELEMENTS:REMOVE';
export const SET_CATEGORIES = 'MEAL:CATEGORIES:SET';
export const SET_RECIPES = 'MEAL:RECIPES:SET';
export const ADD_RECIPE = 'MEAL:RECIPE:ADD';

export const addElement = (element) => ({
    type: ADD_ELEMENT,
    element,
});

export const editCategory = (category) => ({
    type: EDIT_CATEGORY,
    category,
});

export const editElement = (id, data) => ({
    type: EDIT_ELEMENT,
    id,
    data,
});

export const editRecipe = (data) => ({
    type: EDIT_RECIPE,
    data,
});

export const removeElement = (key) => ({
    type: REMOVE_ELEMENT,
    key,
});

export const setCategories = (categories) => ({
    type: SET_CATEGORIES,
    categories,
});

export const setRecipes = (recipes) => ({
    type: SET_RECIPES,
    recipes,
});

export const addRecipe = (recipe) => ({
    type: ADD_RECIPE,
    recipe,
});
