export const mail = { required: 'users.form.errorMail' };

export const firstName = { required: 'users.form.errorFirstname' };

export const lastName = { required: 'users.form.errorLastname' };

export const rules = {
    firstName,
    lastName,
    mail
};
