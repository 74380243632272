import React from 'react';
import PropTypes from 'prop-types';
import useStore from '@src/useStore';
import Locales from '@src/Utils/LocalesUtils';
import StringUtils from '@src/Utils/StringUtils';

const Input = (props) => {
    const { className, error, id, name, placeholder, type } = props;
    const { languages } = useStore();

    /**
     *
     * @returns {*}
     */
    const renderInput = () => {
        let input;

        const classNameArray = (['form-control'].concat([className] || []));
        if (error !== false) {
            classNameArray.push('is-invalid');
        }

        const options = {
            className: classNameArray.join(' '),
            id,
            name,
            ref: props.register
        };

        if (type === 'select') {
            options['defaultValue'] = props.value;
        } else {
            options['type'] = type;
            options['defaultValue'] = props.value;
        }

        if (placeholder) {
            options['placeholder'] = Locales._(languages, placeholder);
        }

        switch (type) {
            case 'select':
                input = (
                    <select {...options}>
                        {props.options.map((opt) => (
                            <option key={opt.id} value={opt.id}>
                                {opt.title}
                            </option>
                        ))}
                    </select>
                );
                break;
            default:
                input = <input {...options} />;
                break;
        }

        return input;
    };

    /**
     * @returns {null|*}
     */
    const renderFeedback = () => {
        return StringUtils.renderFeedback(error, languages);
    };

    return (
        <>
            {renderInput()}
            {renderFeedback()}
        </>
    );
};

Input.propTypes = {
    className: PropTypes.string,
    error: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.bool, PropTypes.string]),
    id: PropTypes.string,
    name: PropTypes.string,
    onChange: PropTypes.func,
    options: PropTypes.array,
    placeholder: PropTypes.string,
    register: PropTypes.func,
    type: PropTypes.oneOf(['email', 'hidden', 'number', 'select', 'text']),
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default Input;
