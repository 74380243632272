import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { FaTrash } from 'react-icons/fa';
import useStore from '../../../useStore';
import Locales from '../../../Utils/LocalesUtils';
import StringUtils from '../../../Utils/StringUtils';
import { addElement, editElement, removeElement } from '../../../Actions/Meals';
import Meals from '../../../Services/Models/Meals';
import DataUtils from '../../../Utils/DataUtils';
import Form from '../../../Views/Helpers/Form';

const Elements = (props) => {
    const { errors } = props;
    const { dispatch, languages, state } = useStore();

    useEffect(() => {
        if (isEmpty(state.meals.categories)) {
            Meals.getCategories({ dispatch });
        }
        // eslint-disable-next-line
    }, []);

    const onChange = (e, id) => {
        e.preventDefault();

        dispatch(editElement(id, { [e.target.name]: e.target.value }));
    };

    const newElement = (e) => {
        e.preventDefault();

        dispatch(
            addElement({
                key: StringUtils.randomKey(),
                category: 0,
                title: '',
                qty: '',
                type: '',
            })
        );
    };

    const trashElement = (e, key) => {
        e.preventDefault();

        dispatch(removeElement(key));
    };

    const renderElements = () => {
        let i = -1;

        return (state.meals.recipe.elements || []).map((element) => {
            i++;
            return (
                <div key={element.id || element.key} className="row mb-2">
                    <div className="col-4">
                        <Form
                            type="text"
                            name="title"
                            placeholder="meals.recipe.form.elements.name"
                            value={element.title}
                            onChange={(e) => onChange(e, element.id || element.key)}
                            hasError={(errors[i] && errors[i].title) || false}
                        />
                    </div>
                    <div className="col-3">
                        <Form
                            type="select"
                            name="category"
                            options={[{id: '', title: Locales._(languages, 'meals.category.categories')}, ...state.meals.categories]}
                            value={element.category}
                            onChange={(e) => onChange(e, element.id || element.key)}
                            hasError={(errors[i] && errors[i].category) || false}
                        />
                    </div>
                    <div className="col-2">
                        <Form
                            type="text"
                            name="qty"
                            placeholder="meals.recipe.form.elements.qty"
                            value={element.qty}
                            onChange={(e) => onChange(e, element.id || element.key)}
                            hasError={(errors[i] && errors[i].qty) || false}
                        />
                    </div>
                    <div className="col-2">
                        <Form
                            type="select"
                            name="type"
                            options={DataUtils.unitOptions.map((unit) => ({
                                id: unit.value,
                                title: Locales._(languages, unit.name)
                            }))}
                            value={element.type}
                            onChange={(e) => onChange(e, element.id || element.key)}
                            hasError={(errors[i] && errors[i].type) || false}
                        />

                    </div>
                    <div className="col-1">
                        <button className="btn btn-danger" onClick={(e) => trashElement(e, element.key)}>
                            <FaTrash />
                        </button>
                    </div>
                </div>
            );
        });
    };

    return (
        <>
            <div className="row mb-2">
                <div className="col-12">
                    <button type="button" className="btn btn-secondary btn-sm" onClick={newElement}>
                        {Locales._(languages, 'meals.recipe.form.elements.add')}
                    </button>
                </div>
            </div>
            {renderElements()}
        </>
    );
};

Elements.propTypes = {
    errors: PropTypes.oneOfType([PropTypes.array, PropTypes.bool, PropTypes.string]),
};

export default Elements;
