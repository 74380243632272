import React, { useContext } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { Context } from '../Store/Context';
import RouteLayout from './RouteLayout';
import LoaderUtils from '../Utils/LoaderUtils';
const Switch = require('react-router-dom').Switch;

const StandardRouter = () => {
    const history = createBrowserHistory();
    const { routes } = useContext(Context);

    const renderRoutes = () => {
        const appsRoutes = [];

        const path = routes.prefix;
        const appRoutes = routes.routes;

        appRoutes.map((route) => {
            const params = route;

            params.layout = LoaderUtils.getLayout(params.layout || routes.layout);
            params.controller = LoaderUtils.getComponent(params.controller);
            params.path = path + params.path;
            params.key = params.name;
            params.isPrivate = params.isPrivate !== undefined ? params.isPrivate : true;

            appsRoutes.push(params);

            return true;
        });

        // Ajout de la page 404
        appsRoutes.push({
            key: 'e404',
            name: 'error404',
            layout: LoaderUtils.getLayout('Frontend'),
            controller: LoaderUtils.getComponent('Frontend.Error'),
        });

        return appsRoutes.map((route, i) => {
            return <RouteLayout key={i} {...route} />;
        });
    };

    return (
        <Router history={history}>
            <Switch>{renderRoutes()}</Switch>
        </Router>
    );
};

export default StandardRouter;
