import React from 'react';
import PropTypes from 'prop-types';
import { FaClipboardList, FaListAlt } from 'react-icons/fa';
import { GiHotMeal } from 'react-icons/gi';
import { MdRestaurantMenu } from 'react-icons/md';
import Shortcut from '@src/Views/UI/Shortcut';
import Menu from '../Modals/Menu';
import List from '../Modals/List';

const Shortcuts = (props) => {
    const { current } = props;

    const render = () => {
        const cols = [];

        if (current) {
            cols.push(
                <div key="meals" className="col-lg-3 mb-1">
                    <Shortcut route="meals" title="meals.explain" subtitle="meals.title" icon={FaListAlt} bg="primary" />
                </div>
            );
        }

        if (current === undefined || current !== 'category') {
            cols.push(
                <div key="meals.category" className="col-lg-3 mb-1">
                    <Shortcut route="meals.category" title="meals.category.explain" subtitle="meals.category.title" icon={FaListAlt} bg="secondary" />
                </div>
            );
        }

        if (current === undefined || current !== 'recipes') {
            cols.push(
                <div key="meals.recipes" className="col-lg-3 mb-1">
                    <Shortcut route="meals.recipes" title="meals.recipe.explain" subtitle="meals.recipe.title" icon={GiHotMeal} bg="tertiary" />
                </div>
            );
        }

        if (current === undefined || current !== 'menu') {
            cols.push(
                <div key="meals.menu" className="col-lg-3 mb-1">
                    <Shortcut
                        modal={{ name: 'meals.menu', modal: Menu }}
                        title="meals.menu.explain"
                        subtitle="meals.menu.title"
                        icon={MdRestaurantMenu}
                        bg="success"
                    />
                </div>
            );
        }

        if (current === undefined || current !== 'elements') {
            cols.push(
                <div key="meals.elements" className="col-lg-3 mb-1">
                    <Shortcut
                        modal={{ name: 'meals.elements', modal: List }}
                        title="meals.elements.explain"
                        subtitle="meals.elements.title"
                        icon={FaClipboardList}
                        bg="warning"
                    />
                </div>
            );
        }

        return cols;
    };

    return <div className="row mt-4 mb-4">{render()}</div>;
};

Shortcuts.propTypes = {
    current: PropTypes.oneOf(['category', 'elements', 'menu', 'recipes']),
};

export default Shortcuts;
