import React, { useEffect, useState } from 'react';
import { FaPlus } from 'react-icons/fa';
import useStore from '../../../useStore';
import Locales from '../../../Utils/LocalesUtils';
import Meals from '../../../Services/Models/Meals';
import Loader from '../../../Views/Helpers/Loader';
import Card from '../../../Views/UI/Card';
import { isEmpty } from 'lodash';
import Bar from '../../../Views/UI/Bar';
import { editRecipe } from '../../../Actions/Meals';
import DataUtils from '../../../Utils/DataUtils';
import NotyUtils from '../../../Utils/NotyUtils';

const Recipes = () => {
    const { dispatch, languages, state } = useStore();
    const [data, setData] = useState(state.meals.recipes);

    useEffect(() => {
        getRecipes();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (state.meals.recipes[0]) {
            setData(state.meals.recipes);
        }
    }, [state.meals.recipes]);

    /**
     * Récupère la liste des recettes
     */
    const getRecipes = () => {
        Meals.getRecipes({
            dispatch,
            onSuccess: (data) => {
                setData(data.response.recipes);

                return data.response.recipes;
            },
        });
    };

    /**
     * @param recipe
     */
    const onEditRecipe = (recipe) => {
        dispatch(editRecipe(recipe));
    };

    /**
     * @param recipe
     */
    const onDeleteRecipe = (recipe) => {
        Meals.removeRecipe(recipe.id).then((data) => {
            getRecipes();
            if (data && data.message) {
                NotyUtils.info(data.message);
            }
        });
    };

    /**
     * Créer une nouvelle recette
     */
    const onResetRecipe = () => {
        dispatch(editRecipe(DataUtils.defaultRecipe()));
    };

    /**
     * @returns {*}
     */
    const renderHeaderRecipes = () => {
        return (
            <div key="recipesHeader" className="row row-header">
                <div className="col-1 text-center">#</div>
                <div className="col-11">{Locales._(languages, 'meals.recipe.table.name')}</div>
            </div>
        );
    };

    /**
     * @returns {array}
     */
    const renderRecipes = () => {
        const header = renderHeaderRecipes();
        const recipes = data.map((recipe) => {
            return (
                <div key={recipe.id} className="row mb-1">
                    <div className="col-1">
                        <Bar data={recipe} onEdit={onEditRecipe} onDelete={onDeleteRecipe} />
                    </div>
                    <div className="col-11">{recipe.title}</div>
                </div>
            );
        });

        return [header, ...recipes];
    };

    return (
        <div className="row">
            <div className="col-12">
                <Loader name="meals.getRecipes" wrapper />
                <Card
                    title="meals.recipe.recipes"
                    dropdown={() => (
                        <>
                            <span className="dropdown-item cp" onClick={onResetRecipe}>
                                <FaPlus /> {Locales._(languages, 'meals.recipe.addRecipe')}
                            </span>
                        </>
                    )}>
                    {isEmpty(data) ? <div className="alert alert-info">{Locales._(languages, 'meals.recipe.noRecipes')}</div> : renderRecipes()}
                </Card>
            </div>
        </div>
    );
};

Recipes.propTypes = {};

export default Recipes;
