import Frontend from '@src/Views/Layouts/Frontend';
import Blank from '@src/Views/Layouts/Blank';

import Dashboard from '@src/Components/Dashboard';

import Login from '@src/Components/User/Login';
import Logout from '@src/Components/User/Logout';

import Meals from '@src/Components/Meals/Dashboard';
import MealsCategory from '@src/Components/Meals/Category';
import MealsRecipes from '@src/Components/Meals/Recipes';

import UsersManage from '@src/Components/User/Manage';
import UsersEdit from '@src/Components/User/Edit';

import BoardGamesEdit from '@src/Components/Games/BoardGames/Edit';
import BoardGamesDashboard from '@src/Components/Games/BoardGames/Dashboard';

const LoaderUtils = {
    data: {
        components: {
            'Frontend.Error': Login,
            Login,
            Logout,
            Dashboard,
            Meals,
            MealsCategory,
            MealsRecipes,
            UsersEdit,
            UsersManage,
            BoardGamesEdit,
            BoardGamesDashboard
        },
        layouts: {
            Blank,
            Frontend,
        },
    },

    /**
     * Get layout or Component
     * @param key
     * @param type
     * @returns {null|*}
     * @private
     */
    _get: (key, type) => {
        if (LoaderUtils.data[type][key]) {
            return LoaderUtils.data[type][key];
        }

        return null;
    },

    /**
     * Get Component
     * @param key
     * @returns {*}
     */
    getComponent: (key) => {
        return LoaderUtils._get(key, 'components');
    },

    /**
     * Get Layout
     * @param key
     * @returns {*}
     */
    getLayout: (key) => {
        return LoaderUtils._get(key, 'layouts');
    },
};

export default LoaderUtils;
