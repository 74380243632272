import React from 'react';
import PropTypes from 'prop-types';

const Title = (props) => {
    return (
        <div className="row">
            <div className="col-12">
                <div className="d-sm-flex align-items-center justify-content-between mb-4">
                    <h1 className="h3 mb-0 text-gray-800">{props.title}</h1>
                    {props.button !== undefined ? <div className="title-buttons">{props.button()}</div> : null}
                </div>
            </div>
        </div>
    );
};

Title.propTypes = {
    button: PropTypes.func,
    title: PropTypes.string,
};

export default Title;
