import Api from '../Api';
import { store } from '../../Store/Local';

const User = {
    /**
     * @param callbackError
     * @returns {*|Promise}
     */
    check: (callbackError) => {
        return Api.getApiPromise({
            request: ['user', 'check'],
            callbackError,
        });
    },
    /**
     * @param email
     * @param password
     * @returns {Promise<T | never>}
     */
    login: ({ email, password }) => {
        return Api.getApiPromise({
            method: 'post',
            data: {
                email,
                password,
            },
            request: ['login'],
        }).then((data) => {
            if (data && data.user) {
                store({
                    expires: data.user.expireAt,
                    token: data.user.token,
                    user: JSON.stringify(data.user),
                });
            }

            return data;
        });
    },
    /**
     * @returns {*|Promise}
     */
    getUsers: ({ onError, onSuccess }) => {
        return Api.getApiPromise({
            method: 'get',
            request: ['users'],
            callbackError: onError,
            callbackSuccess: onSuccess
        });
    },
    /**
     * @param userId
     */
    getUser: ({ onError, onSuccess, userId }) => {
        return Api.getApiPromise({
            method: 'get',
            request: ['users', userId],
            callbackError: onError,
            callbackSuccess: onSuccess
        });
    },
    /**
     * Création d'un utilisateur
     * @param onError
     * @param onSuccess
     * @param user
     * @returns {*|Promise}
     */
    addUser: ({ onError, onSuccess, user }) => {
        return Api.getApiPromise({
            method: 'post',
            request: ['user', 'create'],
            data: user,
            callbackError: onError,
            callbackSuccess: onSuccess
        });
    },
    /**
     * @param {object} user
     * @param onError
     * @param onSuccess
     */
    setUser: ({ onError, onSuccess, user }) => {
        return Api.getApiPromise({
            method: 'patch',
            request: ['user', 'update'],
            data: user,
            callbackError: onError,
            callbackSuccess: onSuccess
        });
    },
};

export default User;
