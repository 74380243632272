import React from 'react';
import PropTypes from 'prop-types';

const Edit = () => {
    return <div>Edit</div>;
};

Edit.propTypes = {
    id: PropTypes.string
};

export default Edit;
