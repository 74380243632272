import { isEmpty } from 'lodash';
import { compile } from 'path-to-regexp';

const LocalesUtils = {
    /**
     * @see getLocale()
     * @private
     */
    _: (locales, msgId, tokens = {}) => {
        let text = locales;
        msgId.split('.').forEach((part) => {
            text = text ? text[part] : null;
        });

        if (Object.values(tokens).length > 0 && text !== null) {
            for (let [key, value] of Object.entries(tokens)) {
                text = text.replace(new RegExp(key, 'g'), value);
            }
        }

        return text || msgId;
    },

    /**
     * Get locale from tree name
     * @param locales
     * @param msgId
     * @param tokens
     * @returns {*}
     */
    getLocale: (locales, msgId, tokens) => {
        return LocalesUtils._(locales, msgId, tokens);
    },

    /**
     * Get path from route name
     *
     * @see https://github.com/pillarjs/path-to-regexp
     *
     * @param {object} routes
     * @param {string} name
     * @param {object} params
     * @returns {*|string}
     */
    getRoute: (routes, name, params = {}) => {
        /** @var {array} routes.routes */
        const findRoute = routes.routes.filter((route) => route.name === name);

        const path = (findRoute[0] && findRoute[0].path) || '/';

        if (!isEmpty(params)) {
            const toPath = compile(path);

            return toPath(params);
        }

        return path;
    },
};

export default LocalesUtils;
