import { isEmpty } from 'lodash';
import { CHANGE_LOADING, FIND_GAME, SET_GAMES, UPDATE_LIST } from '@src/Actions/Boardgames';

export const BoardgamesReducer = (state, action) => {
    switch (action.type) {
        case CHANGE_LOADING: {
            const gameId = action.gameId;
            const games = state.games.map((game) => {
                if (game.id === gameId) {
                    return {
                        ...game,
                        isLoading: action.loading
                    }
                }

                return game;
            });

            return { ...state, games };
        }
        case FIND_GAME: {
            return { ...state, findGame: action.game };
        }
        case SET_GAMES: {
            const categories = [];
            const newGames = action.games.map((game) => {
                const gameCategories = game.categories || [];
                if (!isEmpty(gameCategories)) {
                    gameCategories
                        .filter((category) => !categories.some((cat) => cat.id === category.id))
                        .map((category) => {
                            categories.push(category);

                            return [];
                        });
                }

                return {
                    ...game,
                    isLoading: false,
                };
            });

            return { ...state, games: newGames, categories };
        }
        /**
         * Mise à jour d'un jeu dans la liste
         */
        case UPDATE_LIST: {
            const games = state.games;
            const updatedGame = action.game;

            return {
                ...state,
                games: games.map((game) => {
                    if (game.id === updatedGame.id) {
                        return { ...game, ...updatedGame };
                    }

                    return game;
                }),
            };
        }
        default:
            return state;
    }
};

export const BoardgamesState = {
    categories: [],
    findGame: {},
    games: [],
};
