import Boardgames from '@src/Services/Models/Boardgames';

export const ajaxFindGame = ({ categories }) => {
    return Boardgames.findGame({
        categories,
        onError: () => {},
        onSuccess: (response) => {
            return response.response.game;
        }
    });
};
