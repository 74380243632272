import { useEffect } from 'react';
import PropTypes from 'prop-types';
import useStore from '../../useStore';
import { userLogout } from '../../Actions/User';
import Locales from '../../Utils/LocalesUtils';
import Noty from '../../Utils/NotyUtils';

const Logout = (props) => {
    const { dispatch, languages, routes } = useStore();

    useEffect(() => {
        dispatch(userLogout());
        Noty.info(Locales._(languages, 'logout.success'));

        props.history.push(Locales.getRoute(routes, 'login'));
        // eslint-disable-next-line
    }, []);

    return null;
};

Logout.propTypes = {
    history: PropTypes.object,
};

export default Logout;
