import React from 'react';
import { AiFillFolderAdd } from 'react-icons/ai';
import useStore from '../../useStore';
import Locales from '../../Utils/LocalesUtils';
import Title from '../../Views/UI/Title';
import EditCategory from './Modals/EditCategory';
import Categories from './Elements/Categories';
import { modalOpen } from '../../Actions/Modal';
import Shortcuts from './Elements/Shortcuts';

const Category = () => {
    const { dispatch, languages } = useStore();

    const showModal = () => {
        dispatch(modalOpen('meals.addCategory'));
    };

    const addModal = () => {
        return (
            <>
                <span onClick={showModal} className="d-none d-sm-inline-block btn btn-sm btn-secondary shadow-sm ml-2 cp">
                    <AiFillFolderAdd /> {Locales._(languages, 'meals.category.addCategory')}
                </span>
            </>
        );
    };

    return (
        <>
            <Title title={Locales._(languages, 'meals.category.title')} button={addModal} />
            <div className="row">
                <div className="col-12">
                    <Categories />
                </div>
            </div>
            <Shortcuts current="category" />
            <EditCategory />
        </>
    );
};

Category.propTypes = {};

export default Category;
