import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import LoaderUtils from '../Utils/LoaderUtils';
const Route = require('react-router-dom').Route;

const RouteLayout = (props) => {
    const [rendered, setRendered] = useState(false);

    let RouteComponent = props.controller;
    if (typeof props.controller === 'undefined') {
        RouteComponent = LoaderUtils.getComponent('Frontend.Error');
    }

    let RouteLayout = null;
    if (props.layout === undefined) {
        RouteLayout = LoaderUtils.getLayout('Blank');
    } else {
        RouteLayout = props.layout;
    }

    useEffect(() => {
        setRendered(true);
    }, []);

    return rendered ? (
        <Route
            {...props}
            render={(matchProps) => {
                return (
                    <RouteLayout isPrivate={props.isPrivate} {...matchProps}>
                        <RouteComponent {...matchProps} />
                    </RouteLayout>
                );
            }}
        />
    ) : null;
};

RouteLayout.propTypes = {
    controller: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
    layout: PropTypes.func,
};

export default RouteLayout;
