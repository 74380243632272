import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { FaEllipsisV } from 'react-icons/fa';
import { Context } from '../../Store/Context';
import Locales from '../../Utils/LocalesUtils';
import Dropdown from './Dropdown';

const Card = (props) => {
    const { languages } = useContext(Context);

    const renderDropDown = () => {
        if (typeof props.dropdown === 'function') {
            return (
                <Dropdown
                    button={() => (
                        <>
                            <FaEllipsisV />
                        </>
                    )}>
                    {props.dropdown()}
                </Dropdown>
            );
        }

        return null;
    };

    return (
        <div className="card shadow mb-4">
            <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                <h6 className="m-0 font-weight-bold text-primary">{Locales._(languages, props.title, props.tokens || {})}</h6>
                {renderDropDown()}
            </div>
            <div className="card-body">{props.children}</div>
        </div>
    );
};

Card.propTypes = {
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.string]),
    dropdown: PropTypes.func,
    title: PropTypes.string,
    tokens: PropTypes.object,
};

export default Card;
