import React, { useReducer } from 'react';
import PropTypes from 'prop-types';
import { initialState, rootReducer } from './reducers';
import { getStore } from './Local';

const Store = React.createContext();

const StoreProvider = (props) => {
    const [state, dispatch] = useReducer(rootReducer, { ...initialState, ...getStore() });

    return <Store.Provider value={{ dispatch, state }}>{props.children}</Store.Provider>;
};

StoreProvider.propTypes = {
    children: PropTypes.object,
};

export { Store, StoreProvider };
