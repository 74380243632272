import React, { useEffect, useState } from 'react';
import { FaTimes } from 'react-icons/fa';
import PropTypes from 'prop-types';
import useStore from '@src/useStore';
import Locales from '@src/Utils/LocalesUtils';
import { modalClose } from '@src/Actions/Modal';

const Modal = (props) => {
    const name = props.name || 'default';
    const { dispatch, languages, state } = useStore();
    const [show, setShow] = useState(false);

    useEffect(() => {
        state.modal.modals.map((modal) => {
            if (modal.name === name) {
                setShow(true);
            } else {
                setShow(false);
            }

            return true;
        });
    }, [name, state.modal]);

    const isVisible = () => {
        return show === true ? ' show' : '';
    };

    const confirm = () => {
        if (props.onConfirm) {
            props.onConfirm(close);
        } else {
            close();
        }
    };

    const close = () => {
        setShow(false);
        dispatch(modalClose(name));
        if (props.onClose) {
            props.onClose();
        }
    };

    return (
        <div className={'modal fade' + isVisible()} tabIndex="-1" role="dialog">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">{props.title}</h5>
                        <button type="button" className="close" onClick={close}>
                            <FaTimes />
                        </button>
                    </div>
                    <div className="modal-body">{props.children}</div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-success" onClick={confirm}>
                            {Locales._(languages, 'modal.button.primary')}
                        </button>
                        <button type="button" className="btn btn-danger" onClick={close}>
                            {Locales._(languages, 'modal.button.secondary')}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

Modal.propTypes = {
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.string]),
    name: PropTypes.string,
    onConfirm: PropTypes.func,
    show: PropTypes.bool,
    title: PropTypes.string,
};

export default Modal;
