import { useContext } from 'react';
import { Context } from './Store/Context';
import { Store } from './Store/Store';

const useStore = () => {
    const { languages, routes } = useContext(Context);
    const { dispatch, state } = useContext(Store);

    return {
        dispatch,
        languages,
        routes,
        state,
    };
};

export default useStore;
