import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import useStore from '../../useStore';
import { getItem, getStore, removeStore } from '../../Store/Local';
import User from '../../Services/Models/User';
import { userLogin } from '../../Actions/User';
import Locales from '../../Utils/LocalesUtils';
import Button from '../../Views/UI/Button';
import '../../assets/login.less';

const Login = (props) => {
    const { dispatch, languages, routes } = useStore();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const store = getStore();

        if ((store && store.user) || getItem('token')) {
            User.check((e) => {
                removeStore('expires');
                removeStore('token');
                removeStore('user');

                return e.response;
            }).then((data) => {
                if (data && data.isValidToken === true) {
                    props.history.push(Locales.getRoute(routes, 'dashboard'));
                }
            });
        }
        // eslint-disable-next-line
    }, []);

    const login = (e) => {
        e.preventDefault();
        setLoading(true);

        const email = e.target.email.value;
        const password = e.target.password.value;

        User.login({ email, password })
            .then((data) => {
                if (data && data.user) {
                    dispatch(userLogin(data.user));
                    props.history.push(Locales.getRoute(routes, 'dashboard'));
                } else {
                    setLoading(false);
                }
            })
            .catch(() => setLoading(false));
    };

    return (
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-xl-10 col-lg-12 col-md-9">
                    <div className="card o-hidden border-0 shadow-lg my-5">
                        <div className="card-body p-0">
                            <div className="row">
                                <div className="col-lg-6 offset-lg-3 col-xs-12">
                                    <div className="p-5">
                                        <div className="text-center">
                                            <h1 className="h4 text-gray-900 mb-4">{Locales._(languages, 'login.title')}</h1>
                                        </div>
                                        <form method="POST" className="user" onSubmit={login}>
                                            <div className="form-group">
                                                <input
                                                    type="email"
                                                    name="email"
                                                    className="form-control form-control-user"
                                                    placeholder={Locales._(languages, 'login.email')}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <input
                                                    type="password"
                                                    name="password"
                                                    className="form-control form-control-user"
                                                    placeholder={Locales._(languages, 'login.password')}
                                                />
                                            </div>
                                            <Button loading={loading} css={['btn-primary', 'btn-user', 'btn-block']} msgId="login.login" />
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

Login.propTypes = {
    history: PropTypes.object,
};

export default Login;
