import React, { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import useStore from '../../../useStore';
import Locales from '../../../Utils/LocalesUtils';
import NotyUtils from '../../../Utils/NotyUtils';
import Meals from '../../../Services/Models/Meals';
import Loader from '../../../Views/Helpers/Loader';
import Card from '../../../Views/UI/Card';
import { editCategory } from '../../../Actions/Meals';
import { modalOpen } from '../../../Actions/Modal';
import Bar from '../../../Views/UI/Bar';

const Categories = () => {
    const { dispatch, languages, state } = useStore();

    const [data, setData] = useState(state.meals.categories);

    useEffect(() => {
        getCategories();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (state.meals.categories[0]) {
            setData(state.meals.categories);
        }
    }, [state.meals.categories]);

    /**
     * @param {object} category
     */
    const onCategory = (category) => {
        dispatch(editCategory(category));
        dispatch(modalOpen('meals.addCategory'));
    };

    const getCategories = () => {
        Meals.getCategories({ dispatch }).then((r) => setData(r));
    };

    /**
     * Supprime une catégorie
     * @param category
     */
    const deleteCategory = (category) => {
        Meals.removeCategory(category.id).then((data) => {
            getCategories();
            if (data && data.message) {
                NotyUtils.info(data.message);
            }
        });
    };

    const renderHeaderCategories = () => {
        return (
            <div key="category-header" className="row row-header">
                <div className="col-1 text-center">#</div>
                <div className="col-11">{Locales._(languages, 'meals.category.table.name')}</div>
            </div>
        );
    };

    /**
     * @returns {array}
     */
    const renderCategories = () => {
        const header = renderHeaderCategories();
        const categories = data.map((category) => {
            return (
                <div key={category.id} className="row mb-1">
                    <div className="col-1">
                        <Bar data={category} onEdit={onCategory} onDelete={deleteCategory} />
                    </div>
                    <div className="col-11">{category.title}</div>
                </div>
            );
        });

        return [header, ...categories];
    };

    return (
        <div className="row">
            <div className="col-6">
                <Loader name="meals.getCategories" wrapper />
                <Card title="meals.category.categories">
                    {isEmpty(data) ? (
                        <div className="alert alert-info">{Locales._(languages, 'meals.category.noCategories')}</div>
                    ) : (
                        renderCategories()
                    )}
                </Card>
            </div>
        </div>
    );
};

Categories.propTypes = {};

export default Categories;
