export const EDIT = 'USERS:EDIT';
export const LOGIN = 'USER:LOGIN';
export const LOGOUT = 'USER:LOGOUT';
export const SET_LIST = 'USERS:SET_LIST';
export const UPDATE_LIST = 'USERS:UPDATE_LIST';

export const userLogin = (user) => ({
    type: LOGIN,
    user,
});

export const userLogout = () => ({
    type: LOGOUT,
});

/**
 *
 * @param user
 * @returns {{type: string, user: *}}
 */
export const usersEdit = (user) => ({
    type: EDIT,
    user
});

/**
 * Initialize la liste des users
 * @param users
 * @returns {{type: string, users: *}}
 */
export const usersSetList = (users) => ({
    type: SET_LIST,
    users
});

export const usersUpdate = (user) => ({
    type: UPDATE_LIST,
    user
});
