import React from 'react';
import { isArray, isObject } from 'lodash';
import Locales from "@src/Utils/LocalesUtils";

const StringUtils = {
    compareObjectByName: (a, b) => {
        const titleA = a.title.toUpperCase();
        const titleB = b.title.toUpperCase();

        let comp = 0;
        if (titleA > titleB) {
            comp = 1;
        } else if (titleA < titleB) {
            comp = -1;
        }

        return comp;
    },

    randomKey: () => {
        return Math.random()
            .toString(36)
            .substring(5);
    },

    renderFeedback: (hasError, languages) => {
        if (hasError !== false) {
            let errors = hasError;
            if (isArray(hasError)) {
                errors = hasError.join(' ');
            } else if (isObject(errors)) {
                errors = Locales._(languages, errors.message);
            }

            return <div className="invalid-feedback">{errors}</div>;
        }

        return null;
    },
};

export default StringUtils;
