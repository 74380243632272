import Api from '@src/Services/Api';

const Boardgames = {
    /**
     * @param limit
     * @param onError
     * @param onSuccess
     * @returns {*|Promise}
     */
    getGames: ({ limit = 25, onError, onSuccess }) => {
        return Api.getApiPromise({
            method: 'get',
            request: ['games', 'boardgame', 'games', limit],
            callbackSuccess: onSuccess,
            callbackError: onError,
        });
    },

    /**
     * @param categories
     * @param onError
     * @param onSuccess
     * @returns {*|Promise}
     */
    findGame: ({ categories, onError, onSuccess }) => {
        return Api.getApiPromise({
            method: 'get',
            params: {
                category: categories
            },
            request: ['games', 'boardgame', 'games', 'find'],
            callbackSuccess: onSuccess,
            callbackError: onError,
        });
    },

    setGame: ({ game, onError, onSuccess }) => {
        const gameId = game.id;

        return Api.getApiPromise({
            data: game,
            method: 'patch',
            request: ['games', 'boardgame', 'game', gameId],
            callbackSuccess: onSuccess,
            callbackError: onError,
        });
    },

    addPart: ({ gameId, onError, onSuccess }) => {
        return Api.getApiPromise({
            data: {
                game: gameId
            },
            method: 'post',
            request: ['games', 'boardgame', 'games', 'part'],
            callbackSuccess: onSuccess,
            callbackError: onError,
        });
    }
};

export default Boardgames;
