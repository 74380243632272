import React from 'react';
import PropTypes from 'prop-types';
import ReactMde, { commands } from 'react-mde';
import Showdown from 'showdown';
import useStore from '../../useStore';
import Locales from '../../Utils/LocalesUtils';
import StringUtils from '../../Utils/StringUtils';

import 'react-mde/lib/styles/css/react-mde-all.css';

const Editor = (props) => {
    const { errors, onChange, value } = props;
    const { languages } = useStore();
    const [selectedTab, setSelectedTab] = React.useState('write');

    const converter = new Showdown.Converter({
        tables: true,
        simplifiedAutoLink: true,
        strikethrough: true,
        tasklists: true,
    });

    const listCommands = [
        {
            commands: [commands.boldCommand, commands.italicCommand, commands.linkCommand, commands.checkedListCommand],
        },
    ];

    return (
        <>
            <ReactMde
                className={errors !== false ? 'is-invalid' : null}
                commands={listCommands}
                l18n={{ write: Locales._(languages, 'editor.write'), preview: Locales._(languages, 'editor.preview') }}
                value={value}
                onChange={onChange}
                selectedTab={selectedTab}
                onTabChange={setSelectedTab}
                generateMarkdownPreview={(markdown) => Promise.resolve(converter.makeHtml(markdown))}
            />
            {StringUtils.renderFeedback(errors)}
        </>
    );
};

Editor.propTypes = {
    errors: PropTypes.oneOfType([PropTypes.array, PropTypes.bool, PropTypes.string]),
    onChange: PropTypes.func,
    value: PropTypes.string,
};

export default Editor;
