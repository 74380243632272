import React, { useContext } from 'react';
import { FaSearch } from 'react-icons/fa';
import { Context } from '../../Store/Context';
import Locales from '../../Utils/LocalesUtils';
import Noty from '../../Utils/NotyUtils';

const Search = () => {
    const { languages } = useContext(Context);

    /**
     * Effectuer une recherche
     * @param e
     */
    const search = (e) => {
        e.preventDefault();

        Noty.info(Locales._(languages, 'search.info'));
    };

    return (
        <div>
            <form className="d-none d-sm-inline-block form-inline mr-auto ml-md-3 my-2 my-md-0 mw-100 navbar-search" onSubmit={search}>
                <div className="input-group">
                    <input type="text" className="form-control bg-light border-0 small" placeholder={Locales._(languages, 'search.placeholder')} />
                    <div className="input-group-append">
                        <button className="btn btn-primary" type="submit">
                            <FaSearch />
                        </button>
                    </div>
                </div>
            </form>
        </div>
    );
};

Search.propTypes = {};

export default Search;
