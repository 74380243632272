import axios from 'axios';
import { api_url } from '../Resources/Env';
import NotyUtils from '../Utils/NotyUtils';
import { promise } from '../Utils/Logger';

const Api = {
    /**
     * @param request
     * @returns {string}
     */
    getUrl: (request) => {
        let parts = '';
        if (Array.isArray(request)) {
            parts = request.join('/');
        }

        return api_url + '/' + parts;
    },

    /**
     * @param method string
     * @param data object
     * @param params object
     * @param headers object
     * @param request array
     * @param callbackSuccess
     * @param callbackError  func
     * @returns {Promise}
     */
    getApiPromise: ({
        method = 'get',
        data = {},
        params = {},
        headers = {},
        request = [],
        callbackSuccess,
        callbackError,
    }) => {
        const defaultHeaders = {
            Accept: 'application/json',
            'Content-type': 'application/json',
        };
        const token = localStorage.getItem('token');
        if (token) {
            defaultHeaders['Credential'] = token;
        }
        const settings = {
            method,
            url: Api.getUrl(request),
            data,
            params,
            headers: { ...headers, ...defaultHeaders },
        };

        return axios(settings)
            .then((response) => response.data)
            .then((data) => {
                promise(settings, data, { mode: 'success' });

                if (callbackSuccess !== undefined) {
                    return callbackSuccess(data);
                }

                return data.response;
            })
            .catch((err) => {
                promise(settings, err, { mode: 'error' });

                if (callbackError !== undefined) {
                    return callbackError((err.response && err.response.data) || err);
                } else {
                    if (
                        err.response &&
                        err.response.data &&
                        err.response.data.response &&
                        err.response.data.response.errors
                    ) {
                        return err.response.data.response;
                    }

                    if (
                        err.response &&
                        err.response.data &&
                        err.response.data.response &&
                        err.response.data.response.message
                    ) {
                        NotyUtils.error(err.response.data.response.message);

                        return err.response.data.response.message;
                    }

                    NotyUtils.error('Error');
                }
            });
    },
};

export default Api;
