import React, { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import useStore from '../../useStore';

const Loader = (props) => {
    const { mounted, name } = props;
    const { state } = useStore();
    const [isLoading, setLoading] = useState(true);

    let className = ['loader-ring'];
    if (props.large) {
        className.push('xl');
    }

    /**
     * Au chargement du loader, on vérifie si on est en chargement ou pas
     */
    useEffect(() => {
        const isTracking = state.tracker.promises.filter((promise) => promise.name === name);
        if (isEmpty(isTracking)) {
            setLoading(false);
        }
        // eslint-disable-next-line
    }, []);

    /**
     * Lors du "track" d'une promise, on modifie l'affichage
     */
    useEffect(() => {
        state.tracker.promises.map((promise) => {
            if (promise.name === name) {
                setLoading(promise.loading);
            }

            return true;
        });
    }, [name, state.tracker.promises]);

    const renderLoader = () => {
        if (mounted) {
            return (
                <div className="loader-mounted">
                    <div className={className.join(' ')}>
                        <div />
                        <div />
                        <div />
                        <div />
                    </div>
                </div>
            );
        }

        return (
            <div className={className.join(' ')}>
                <div />
                <div />
                <div />
                <div />
            </div>
        );
    };

    const render = () => {
        if (props.wrapper) {
            return <div className={'loader-wrapper' + (isLoading ? ' loading' : '')}>{renderLoader()}</div>;
        } else {
            return renderLoader();
        }
    };

    return render();
};

Loader.propTypes = {
    large: PropTypes.bool,
    name: PropTypes.string,
    wrapper: PropTypes.bool,
};

export default Loader;
